import React, {Suspense, lazy} from "react";
import {useEffect, useState} from "react";

//Router DOM
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import PrivateRoute from "./private";
import UserAccessRoute from "./useraccess";
//COMPONENTS

import Loader from "../components/generic/loader";
import ScrollToTop from "../components/generic/scrolltop";
import Layout from "./layout"; // Import the Layout component

const HomeLazy = lazy(() => import("../pages/homepage"));
const AboutLazy = lazy(() => import("../pages/company"));
const PartnersLazy = lazy(() => import("../pages/partners"));
const DirectoryLazy = lazy(() => import("../pages/directory"));
const QuoteLazy = lazy(() => import("../pages/quote"));
const CareersLazy = lazy(() => import("../pages/careers"));
const CareersListingLazy = lazy(() => import("../pages/careers-listing"));
const ShippingLeadsLazy = lazy(() => import("../pages/shipping-leads"));
const NetworkingLazy = lazy(() => import("../pages/networking"));
const FreeListingLazy = lazy(() => import("../pages/freelisting"));

const FaqLazy = lazy(() => import("../pages/faq"));
const MembershipLazy = lazy(() => import("../pages/membership"));
const MembershipStepOneFreeLazy = lazy(() => import("../pages/free-membership/index"));
const MembershipCreateFreeLazy = lazy(() => import("../pages/free-membership/signUp"));
const MembershipUpdateFreeLazy = lazy(() => import("../pages/free-membership/update"));

const ForgotPasswordLazy = lazy(() => import("../pages/forgot-password/index"));
const SetPasswordLazy = lazy(() => import("../pages/set-password/index"));

const MembershipStepOnePreLazy = lazy(() => import("../pages/premium-membership/index"));

const LoginLazy = lazy(() => import("../pages/login/index"));
const SupportLoginLazy = lazy(() => import("../pages/support/login/index"));

const MainLazy = lazy(() => import("../pages/dashboard/main/index"));
const ProfileLazy = lazy(() => import("../pages/dashboard/profile/index"));
const UpgradeProfileLazy = lazy(() => import("../pages/dashboard/profile/upgrade"));

const WarehouseLazy = lazy(() => import("../pages/dashboard/warehouse/index"));
const WarehouseProviderLazy = lazy(() => import("../pages/dashboard/warehouse/providers/index"));
const WarehouseSeekersLazy = lazy(() => import("../pages/dashboard/warehouse/seekers/index"));
const WarehouseRequestLazy = lazy(() => import("../pages/dashboard/warehouse/requests/index"));
const WarehouselistingLazy = lazy(() => import("../pages/dashboard/warehouse/listing/index"));

const EmptyListingLazy = lazy(() => import("../pages/dashboard/empty-container/listing/index"));
const EmptyContainRequest = lazy(() => import("../pages/dashboard/empty-container/requests/index"));

const NewRequestLazy = lazy(() => import("../pages/dashboard/new-request/index"));
// const InboxLazy = lazy(() => import("../pages/dashboard/index/index"))
const ShippingLazy = lazy(() => import("../pages/dashboard/shippingleads/index"));
const FindAgentLazy = lazy(() => import("../pages/dashboard/find-agent/index"));
const PartnerLazy = lazy(() => import("../pages/dashboard/partner/index"));

const FreightAirLazy = lazy(() => import("../pages/freight-air"));
const FreightRoadsLazy = lazy(() => import("../pages/freight-roads"));
const FreightSeaLazy = lazy(() => import("../pages/freight-sea"));

const ServicesLazy = lazy(() => import("../pages/services"));
const VisionLazy = lazy(() => import("../pages/vision"));

const ContactUsLazy = lazy(() => import("../pages/contactus"));

const NotificationsLazy = lazy(() => import("../pages/dashboard/notification/index"));
const MyProfileLazy = lazy(() => import("../pages/dashboard/myprofile/index"));
const ActiveSessionLazy = lazy(() => import("../pages/dashboard/active-sesions/index"));
const AllLeadsLazy = lazy(() => import("../pages/dashboard/all-leads/index"));

const FreightLazy = lazy(() => import("../pages/dashboard/freight/index"));
const CargoClearanceLazy = lazy(() => import("../pages/dashboard/cargo-clearance/index"));
const StorageYardLazy = lazy(() => import("../pages/dashboard/storage-yard/index"));
const TradingBuyingLazy = lazy(() => import("../pages/dashboard/trading-buying/index"));
const LeasingUsingLazy = lazy(() => import("../pages/dashboard/leasing-using/index"));
const WarehouseGetLazy = lazy(() => import("../pages/dashboard/get-warehouse/index"));
const TransportLazy = lazy(() => import("../pages/dashboard/get-transport/index"));
const ImporterLazy = lazy(() => import("../pages/dashboard/importer/index"));

const OfferFreightLazy = lazy(() => import("../pages/dashboard/offer-freight/index"));
const OfferCargoClearanceLazy = lazy(() => import("../pages/dashboard/offer-cargo-clearance/index"));
const OfferStorageYardLazy = lazy(() => import("../pages/dashboard/offer-storage-yard/index"));
const OfferTradingSellingLazy = lazy(() => import("../pages/dashboard/trading-selling/index"));
const OfferLeasingSupplyingLazy = lazy(() => import("../pages/dashboard/leasing-supplying/index"));
const OfferWarehouseGetLazy = lazy(() => import("../pages/dashboard/offer-warehouse/index"));
const OfferTransportLazy = lazy(() => import("../pages/dashboard/offer-transport/index"));
const ExporterLazy = lazy(() => import("../pages/dashboard/exporter/index"));

const SentLazy = lazy(() => import("../pages/dashboard/index/sent/index"));
const ReceivedLazy = lazy(() => import("../pages/dashboard/index/received/index"));

const ReceivedRequestLazy = lazy(() => import("../pages/dashboard/leads/request-leads/index"));
const MyRequestLazy = lazy(() => import("../pages/dashboard/leads/my-leads/index"));

const NegotiationLazy = lazy(() => import("../pages/dashboard/negotiation/index"));

const LeadListLazy = lazy(() => import("../pages/dashboard/leads/leads-list"));
const OfferedLeadListLazy = lazy(() => import("../pages/dashboard/leads/offered-leads"));
const LeadDetailsLazy = lazy(() => import("../pages/dashboard/leads/leads-details"));

const CookieLazy = lazy(() => import("../pages/policy/cookie"));
const PrivacyLazy = lazy(() => import("../pages/policy/privacy"));
const TermsLazy = lazy(() => import("../pages/policy/terms"));
const BlogsLazy = lazy(() => import("../pages/blog/index"));

const BlogOneLazy = lazy(() => import("../pages/blog/blogone"));
const BlogTwoLazy = lazy(() => import("../pages/blog/blogtwo"));
const BlogThreeLazy = lazy(() => import("../pages/blog/three"));
const BlogFourLazy = lazy(() => import("../pages/blog/four"));
const BlogFiveLazy = lazy(() => import("../pages/blog/five"));
const BlogSixLazy = lazy(() => import("../pages/blog/six"));
const BlogSevenLazy = lazy(() => import("../pages/blog/seven"));
const BlogEightLazy = lazy(() => import("../pages/blog/eight"));
const BlogNineLazy = lazy(() => import("../pages/blog/nine"));

const BlogTenLazy = lazy(() => import("../pages/blog/ten"));
const BlogElevanLazy = lazy(() => import("../pages/blog/elevan"));
const BlogTwelveLazy = lazy(() => import("../pages/blog/twelve"));

const UserProfileUpdateLazy = lazy(() => import("../pages/update-profile/index"));

const SupportDashboardLazy = lazy(() => import("../pages/support/dashboard/index"));
const SupportCreateLeadLazy = lazy(() => import("../pages/support/dashboard/forms/index"));
const SupportAllLeadsLazy = lazy(() => import("../pages/support/dashboard/all-leads/index"));
const SupportAccptedLeadsLazy = lazy(() => import("../pages/support/dashboard/all-leads/accepted"));
const SupportRejectedLeadsLazy = lazy(() => import("../pages/support/dashboard/all-leads/rejected"));
const SupportOfferedLeadsLazy = lazy(() => import("../pages/support/dashboard/all-leads/offered"));
const SupportNegotiateLeadsLazy = lazy(() => import("../pages/support/dashboard/all-leads/negotiate"));
const SupportWaitingLeadsLazy = lazy(() => import("../pages/support/dashboard/all-leads/waiting"));

const SupportAllLeadListsLazy = lazy(() => import("../pages/support/dashboard/all-leads/list"));

const SupportCompanyLeadsLazy = lazy(() => import("../pages/support/dashboard/company-list/index"));
const SupportCompanyByLeadsLazy =lazy(() => import("../pages/support/dashboard/companybylead"));
const SupportFreightLazy = lazy(() => import("../pages/support/dashboard/forms/freight/index"));
const SupportCargoClearanceLazy = lazy(() => import("../pages/support/dashboard/forms/cargo-clearance/index"));
const SupportStorageYardLazy = lazy(() => import("../pages/support/dashboard/forms/storage-yard/index"));
const SupportTradingBuyingLazy = lazy(() => import("../pages/support/dashboard/forms/trading-buying/index"));
const SupportLeasingUsingLazy = lazy(() => import("../pages/support/dashboard/forms/leasing-using/index"));
const SupportWarehouseGetLazy = lazy(() => import("../pages/support/dashboard/forms/get-warehouse/index"));
const SupportTransportLazy = lazy(() => import("../pages/support/dashboard/forms/get-transport/index"));
const SupportImporterLazy = lazy(() => import("../pages/support/dashboard/forms/importer/index"));

const SupportOfferFreightLazy = lazy(() => import("../pages/support/dashboard/forms/offer-freight/index"));
const SupportOfferStorageYardLazy = lazy(() => import("../pages/support/dashboard/forms/offer-storage-yard/index"));
const SupportOfferTradingSellingLazy = lazy(() => import("../pages/support/dashboard/forms/trading-selling/index"));
const SupportOfferLeasingSupplyingLazy = lazy(() => import("../pages/support/dashboard/forms/leasing-supplying/index"));
const SupportOfferWarehouseGetLazy = lazy(() => import("../pages/support/dashboard/forms/offer-warehouse/index"));
const SupportOfferTransportLazy = lazy(() => import("../pages/support/dashboard/forms/offer-transport/index"));
const SupportExporterLazy = lazy(() => import("../pages/support/dashboard/forms/exporter/index"));
const SupportLeadDetailsLazy =lazy(() => import("../pages/support/dashboard/details/index"));
const UsersListLazy = lazy(() => import("../pages/support/dashboard/user/index"));
const UsersDetailsLazy = lazy(() => import("../pages/support/dashboard/user/details"));

const ThankYouPageLazy = lazy(() => import("../pages/thank-you/index"));
const CountryListLazy = lazy(() => import("../pages/support/dashboard/country-list/index"));
const CountryCompanyListLazy = lazy(() => import("../pages/support/dashboard/country-list/details"));

function AllRoutes() {
    const [loading, setLoading] = useState(false);
    const userId = sessionStorage.getItem("user_id");
    const userName = sessionStorage.getItem("user_name");
    const isLoggedIn = userId && userName;
    console.log(isLoggedIn, "isLoggedIn check");

    return (
        <>
            <BrowserRouter>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <ScrollToTop />
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    {/* Public Url Starts */}
                                    <Route index element={<HomeLazy />} />
                                    <Route path="update-profile" element={<UserProfileUpdateLazy />} />
                                    <Route path="company" element={<AboutLazy />} />
                                    <Route path="thank-you" element={<ThankYouPageLazy />} />
                                    <Route path="directory" element={<DirectoryLazy />} />
                                    <Route path="quote" element={<QuoteLazy />} />
                                    <Route path="partners" element={<PartnersLazy />} />
                                    <Route path="membership" element={<MembershipLazy />} />
                                    <Route path="careers" element={<CareersLazy />} />
                                    <Route path="careers/listing" element={<CareersListingLazy />} />
                                    <Route path="shipping-leads" element={<ShippingLeadsLazy />} />
                                    <Route path="faqs" element={<FaqLazy />} />
                                    <Route path="freight/air" element={<FreightAirLazy />} />
                                    <Route path="freight/roads" element={<FreightRoadsLazy />} />
                                    <Route path="freight/sea" element={<FreightSeaLazy />} />
                                    <Route path="networking" element={<NetworkingLazy />} />
                                    <Route path="free-listing" element={<FreeListingLazy />} />
                                    <Route path="privacy-policy" element={<PrivacyLazy />} />
                                    <Route path="cookie-policy" element={<CookieLazy />} />
                                    <Route path="terms-and-conditions" element={<TermsLazy />} />
                                    <Route path="contactus" element={<ContactUsLazy />} />
                                    <Route path="services" element={<ServicesLazy />} />
                                    <Route path="vision-mission" element={<VisionLazy />} />
                                    <Route path="membership/free" element={<MembershipStepOneFreeLazy />} />
                                    <Route path="membership/free/signup" element={<MembershipCreateFreeLazy />} />
                                    <Route path="membership/free/update" element={<MembershipUpdateFreeLazy />} />
                                    <Route path="membership/premium" element={<MembershipStepOnePreLazy />} />
                                    <Route path="blogs" element={<BlogsLazy />} />
                                    <Route
                                        path="blog/worlds-first-unified-platform-to-connect-all-shipping-businesses"
                                        element={<BlogOneLazy />}
                                    />
                                    <Route path="blog/unveiling-the-economic-powerhouse" element={<BlogTwoLazy />} />
                                    <Route
                                        path="blog/how-federalboxx-revolutionizes-global-shipping-comprehensive-guide"
                                        element={<BlogThreeLazy />}
                                    />
                                    <Route
                                        path="blog/unlock-your-company-global-shippping-potential"
                                        element={<BlogFourLazy />}
                                    />
                                    <Route path="blog/from-local-to-global" element={<BlogFiveLazy />} />
                                    <Route path="blog/efficiency-at-your-fingertips" element={<BlogSixLazy />} />
                                    <Route path="blog/mastering-global-sourcing" element={<BlogSevenLazy />} />
                                    <Route path="blog/connect-showcase-thrive" element={<BlogEightLazy />} />
                                    <Route path="blog/effortless-container-trading-and-leasing" element={<BlogNineLazy />} />

                                    <Route path="blog/simplifying-global-commerce-for-importers-and-exporters" element={<BlogTenLazy />} />
                                    <Route path="blog/simplifying-your-experience-on-federalBoxx" element={<BlogElevanLazy />} />
                                    <Route path="blog/the-future-of-empty-container-storage" element={<BlogTwelveLazy />} />


                                    {/* Private Urls */}
                                    <Route
                                        path="/dashboard"
                                        element={
                                            <UserAccessRoute>
                                                <MainLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    {/* <Route path="dashboard" element={<MainLazy />} /> */}
                                    <Route
                                        path="dashboard/profile"
                                        element={
                                            <UserAccessRoute>
                                                <ProfileLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/profile/upgrade"
                                        element={
                                            <UserAccessRoute>
                                                <UpgradeProfileLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/warehouse/providers"
                                        element={
                                            <UserAccessRoute>
                                                <WarehouseProviderLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/warehouse/seekers"
                                        element={
                                            <UserAccessRoute>
                                                <WarehouseSeekersLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/warehouse/listing"
                                        element={
                                            <UserAccessRoute>
                                                <WarehouselistingLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/warehouse/requests"
                                        element={
                                            <UserAccessRoute>
                                                <WarehouseRequestLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/empty-container/listing"
                                        element={
                                            <UserAccessRoute>
                                                <EmptyListingLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/empty-container/requests"
                                        element={
                                            <UserAccessRoute>
                                                <EmptyContainRequest />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/shipping-leads"
                                        element={
                                            <UserAccessRoute>
                                                <ShippingLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/find-agent"
                                        element={
                                            <UserAccessRoute>
                                                <FindAgentLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/partner"
                                        element={
                                            <UserAccessRoute>
                                                <PartnerLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/new-request"
                                        element={
                                            <UserAccessRoute>
                                                <NewRequestLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/notifications"
                                        element={
                                            <UserAccessRoute>
                                                <NotificationsLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/myprofile"
                                        element={
                                            <UserAccessRoute>
                                                <MyProfileLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/activesessions"
                                        element={
                                            <UserAccessRoute>
                                                <ActiveSessionLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/all-leads"
                                        element={
                                            <UserAccessRoute>
                                                <AllLeadsLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/freight"
                                        element={
                                            <UserAccessRoute>
                                                <FreightLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/cargo-clearance"
                                        element={
                                            <UserAccessRoute>
                                                <CargoClearanceLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/storage-yard"
                                        element={
                                            <UserAccessRoute>
                                                <StorageYardLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/trading-buying"
                                        element={
                                            <UserAccessRoute>
                                                <TradingBuyingLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/leasing-using"
                                        element={
                                            <UserAccessRoute>
                                                <LeasingUsingLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/warehouse"
                                        element={
                                            <UserAccessRoute>
                                                <WarehouseGetLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/transport"
                                        element={
                                            <UserAccessRoute>
                                                <TransportLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/getquote/importer"
                                        element={
                                            <UserAccessRoute>
                                                <ImporterLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/freight"
                                        element={
                                            <UserAccessRoute>
                                                <OfferFreightLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/cargo-clearance"
                                        element={
                                            <UserAccessRoute>
                                                <OfferCargoClearanceLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/storage-yard"
                                        element={
                                            <UserAccessRoute>
                                                <OfferStorageYardLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/trading-selling"
                                        element={
                                            <UserAccessRoute>
                                                <OfferTradingSellingLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/leasing-supplying"
                                        element={
                                            <UserAccessRoute>
                                                <OfferLeasingSupplyingLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/warehouse"
                                        element={
                                            <UserAccessRoute>
                                                <OfferWarehouseGetLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/transport"
                                        element={
                                            <UserAccessRoute>
                                                <OfferTransportLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offerquote/exporter"
                                        element={
                                            <UserAccessRoute>
                                                <ExporterLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/negotiation"
                                        element={
                                            <UserAccessRoute>
                                                <NegotiationLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/request/myrequest"
                                        element={
                                            <UserAccessRoute>
                                                <MyRequestLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/request/receivedrequest"
                                        element={
                                            <UserAccessRoute>
                                                <ReceivedRequestLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/leads"
                                        element={
                                            <UserAccessRoute>
                                                <LeadListLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/offered-leads"
                                        element={
                                            <UserAccessRoute>
                                                <OfferedLeadListLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/leads/details/:messageId"
                                        element={
                                            <UserAccessRoute>
                                                <LeadDetailsLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/sent"
                                        element={
                                            <UserAccessRoute>
                                                <SentLazy />
                                            </UserAccessRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard/received"
                                        element={
                                            <UserAccessRoute>
                                                <ReceivedLazy />
                                            </UserAccessRoute>
                                        }
                                    />

                                    <Route path="/forgot-password" element={<ForgotPasswordLazy />} />
                                    <Route path="/set-password" element={<SetPasswordLazy />} />
                                    <Route path="/login" element={<LoginLazy />} />
                                </Route>

                                {/* Routes without Header and Footer */}

                                <Route path="/support/login" element={<SupportLoginLazy />} />

                                {/* Private Routes */}
                                <Route
                                    path="/support/dashboard"
                                    element={
                                        <PrivateRoute>
                                            <SupportDashboardLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/create-leads"
                                    element={
                                        <PrivateRoute>
                                            <SupportCreateLeadLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/all-leads"
                                    element={
                                        <PrivateRoute>
                                            <SupportAllLeadsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/accepted/leads"
                                    element={
                                        <PrivateRoute>
                                            <SupportAccptedLeadsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/offered/leads"
                                    element={
                                        <PrivateRoute>
                                            <SupportOfferedLeadsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/rejected/leads"
                                    element={
                                        <PrivateRoute>
                                            <SupportRejectedLeadsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/negotiated/leads"
                                    element={
                                        <PrivateRoute>
                                            <SupportNegotiateLeadsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/waiting/leads"
                                    element={
                                        <PrivateRoute>
                                            <SupportWaitingLeadsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/all-leads/list/:leadid"
                                    element={
                                        <PrivateRoute>
                                            <SupportAllLeadListsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/all-leads/details/:message_id"
                                    element={
                                        <PrivateRoute>
                                            <SupportLeadDetailsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/users"
                                    element={
                                        <PrivateRoute>
                                            <UsersListLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/users/:user_id"
                                    element={
                                        <PrivateRoute>
                                            <UsersDetailsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/country-list"
                                    element={
                                        <PrivateRoute>
                                            <CountryListLazy />
                                        </PrivateRoute>
                                    }
                                />
                                 <Route
                                    path="/support/country-list/:countryname"
                                    element={
                                        <PrivateRoute>
                                            <CountryCompanyListLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/company-list"
                                    element={
                                        <PrivateRoute>
                                            <SupportCompanyLeadsLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                        path="/support/company-list/:companyId"
                                        element={
                                            <PrivateRoute>
                                                <SupportCompanyByLeadsLazy />
                                            </PrivateRoute>
                                        }
                                    />
                                <Route
                                    path="/support/dashboard/getquote/freight"
                                    element={
                                        <PrivateRoute>
                                            <SupportFreightLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/getquote/cargo-clearance"
                                    element={
                                        <PrivateRoute>
                                            <SupportCargoClearanceLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/getquote/storage-yard"
                                    element={
                                        <PrivateRoute>
                                            <SupportStorageYardLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/getquote/trading-buying"
                                    element={
                                        <PrivateRoute>
                                            <SupportTradingBuyingLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/getquote/leasing-using"
                                    element={
                                        <PrivateRoute>
                                            <SupportLeasingUsingLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/getquote/warehouse"
                                    element={
                                        <PrivateRoute>
                                            <SupportWarehouseGetLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/getquote/transport"
                                    element={
                                        <PrivateRoute>
                                            <SupportTransportLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/getquote/importer"
                                    element={
                                        <PrivateRoute>
                                            <SupportImporterLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/offerquote/freight"
                                    element={
                                        <PrivateRoute>
                                            <SupportOfferFreightLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/offerquote/storage-yard"
                                    element={
                                        <PrivateRoute>
                                            <SupportOfferStorageYardLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/offerquote/trading-selling"
                                    element={
                                        <PrivateRoute>
                                            <SupportOfferTradingSellingLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/offerquote/leasing-supplying"
                                    element={
                                        <PrivateRoute>
                                            <SupportOfferLeasingSupplyingLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/offerquote/warehouse"
                                    element={
                                        <PrivateRoute>
                                            <SupportOfferWarehouseGetLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/offerquote/transport"
                                    element={
                                        <PrivateRoute>
                                            <SupportOfferTransportLazy />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/support/dashboard/offerquote/exporter"
                                    element={
                                        <PrivateRoute>
                                            <SupportExporterLazy />
                                        </PrivateRoute>
                                    }
                                />
                                {/* Fallback for non-existent routes */}
                                {/* Fallback for non-existent routes */}
                            </Routes>
                        </Suspense>
                    </>
                )}
            </BrowserRouter>
        </>
    );
}

export default AllRoutes;
